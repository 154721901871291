<template>
  <div class="layout_bottom">
    <div class="inner flex jc-sb ai-c">
      <div class="center_title">
        <img src="@/assets/images/loanImg/logo_2.png" alt="">
        <span class="title">Easy and convenient<br>
          online loan.</span>
      </div>
      <div class="item_download" @click="downloadApp()">
        <img src="@/assets/images/Google.png" alt="">
      </div>
      <div class="center">
        <div class="center_1">
          <img src="@/assets/images/email.png" alt="">
          <div>
            <p>Email</p>
            <p>easykashapp@outlook.com</p>
          </div>
        </div>
        <div class="center_1">
          <img src="@/assets/images/address.png" alt="">
          <div>
            <p>Address</p>
            <p>Sahadatu Plaza,70d,Allen Avenue,Ikeja,Lagos State</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner2">
      <div class="center_1">
        <span class="title">Copyright © EasyKash All rights reserved</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    downloadApp() {
      window.location.href = 'https://play.google.com/store/apps/details?id=easykash.creditapp.loan'
    }
  }
}
</script>
<style lang="scss" scoped>
.layout_bottom{
  display: flex;
  // align-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  img{
    margin-right: 10px;
    width: 40px;
    display: inline-block;
  }
  .inner{
    padding:50px calc((100% - 1100px) / 2);
    background: #060300;
  }
  .center_title{
    display: flex;
    align-items: center;
    // margin-bottom: 40px;
  }
  .item_download {
    img{
      display: block;
      width: 130px;
      height: 40px;
    }
  }
  .center{
    .center_1{
      display: flex;
      align-items: center;
      font-size: 12px;
      & p:nth-of-type(1){
        margin-bottom: 5px;
      }
      img{
        display: block;
        background: white;
        width: 34px;
        border-radius: 17px;
      }
    }
    & .center_1:nth-of-type(1){
      margin-bottom: 20px;
      img{
        // width: 22px;
        padding: 6px;
      }
    }
  }
  .inner2{
    padding:20px calc((100% - 1100px) / 2);
    background: #4D4D4D;
    text-align: center;
  }
}
@media only screen and (max-width: 720px){
  .layout_bottom{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    padding: 20px 0px 0px;
    .inner{
      width: 100%;
      flex-direction: column;
      padding: 50px 20px;
      align-items: flex-start;
    }
    .center_title{
      margin-bottom: 20px;
    }
    .center{
      min-width: 100%;
      margin: 0px auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0;
      flex-direction: column;
      .center_1{
        display: flex;
        align-items: center;
        margin-top: 15px;
      }
      & .center_1:nth-of-type(1){
        margin-bottom: 0px;
      }
      img{
        margin-right: 10px;
        width: 40px;
        display: inline-block;
      }
    }
    .inner2{
      padding: 20px ;
    }
  }
}
</style>
